import { Module } from "vuex";
import { RootState } from "../index";
export interface UserState {
  recentSearches: number[];
}

export const user: Module<UserState, RootState> = {
  state: {
    recentSearches: [1, 13, 15, 30, 32, 33, 34, 38, 40, 42, 43, 48],
  },
  getters: {
    getRecentSearches(state, getters) {
      const data = getters.getApplicationList.map((v: any) => {
        let guideList = v.guideList.reduce(
          (acc: any, guideListValue: any, idx: any) => {
            if (state.recentSearches.indexOf(guideListValue._id) != -1) {
              return [
                ...acc,
                {
                  name: v.name,
                  guideName: guideListValue.name,
                  replay: guideListValue.replay,
                },
              ];
            }
            return acc;
          },
          []
        );
        return guideList;
      });
      return data.flat();
    },
  },
  actions: {},
  mutations: {},
};
