import { Module } from "vuex";
import { RootState } from "../index";

export type GuideListType = {
  _id: number;
  name: string;
  replay: boolean;
  serachList: string[];
  url?: string;
};

export type ListType = {
  name: string;
  guideList: GuideListType[];
};

export interface ApplicationState {
  list: ListType[];
}

export const application: Module<ApplicationState, RootState> = {
  state: {
    list: [
      {
        name: "요즘세상",
        guideList: [
          {
            _id: 1,
            name: "검색방법",
            replay: false,
            serachList: ["요즘세상", "검색방법"],
            url: "/thesedays/search_ways",
          },
          {
            _id: 2,
            name: "문의하기",
            replay: false,
            serachList: ["요즘세상", "문의하기", "문의", "문의방법"],
            url: "/thesedays/inquire",
          },
          {
            _id: 3,
            name: "목소리 변경",
            replay: false,
            serachList: [
              "요즘세상",
              "목소리 변경",
              "자막",
              "자막소리",
              "소리변경",
              "목소리",
            ],
            url: "/thesedays/change_narration",
          },
          {
            _id: 4,
            name: "시간 설정",
            replay: false,
            serachList: ["요즘세상", "안내시간", "노란색동그라미", "시간설정"],
            url: "/thesedays/setting_time",
          },
          {
            _id: 5,
            name: "다시보기",
            replay: false,
            serachList: ["요즘세상", "별", "다시보기"],
            url: "/thesedays/replay",
          },
        ],
      },
      {
        name: "문자",
        guideList: [
          {
            _id: 6,
            name: "스팸문자 등록",
            replay: false,
            serachList: [
              "문자",
              "스팸문자",
              "스팸문자추가",
              "스팸등록",
              "스팸추가",
              "스팸문자등록",
            ],
            url: "/message/add_spam_messages",
          },
          {
            _id: 7,
            name: "스팸문자 해제",
            replay: false,
            serachList: ["문자", "스팸문자", "스팸해제", "스팸문자해제"],
            url: "/message/remove_spam_messages",
          },
          {
            _id: 8,
            name: "사진 파일 첨부",
            replay: false,
            serachList: [
              "문자",
              "사진보내기",
              "사진첨부",
              "보내는방법",
              "사진파일첨부",
            ],
            url: "/message/attach_photo",
          },
          {
            _id: 9,
            name: "다른 사진 첨부",
            replay: false,
            serachList: [
              "문자",
              "사진보내기",
              "사진첨부",
              "다른사진보내기",
              "다른사진",
              "다른사진첨부",
            ],
            url: "/message/attach_another_picture",
          },
          {
            _id: 10,
            name: "자판 연습",
            replay: false,
            serachList: ["문자", "자판연습", "문자연습", "자판"],
            url: "/message/practicing_keyboards",
          },
          {
            _id: 11,
            name: "문자 전송 확인",
            replay: false,
            serachList: ["문자", "묹나보내기", "보내는방법", "문자전송확인"],
            url: "/message/confirm_text_transfer",
          },
          {
            _id: 12,
            name: "문자 수신 확인",
            replay: false,
            serachList: ["문자", "문자확인", "받은문자", "문자수신확인"],
            url: "/message/confirm_receipt_text",
          },
        ],
      },
      {
        name: "전화",
        guideList: [
          {
            _id: 13,
            name: "영상통화",
            replay: false,
            serachList: ["전화", "영상통화"],
            url: "/call/videocall",
          },
          {
            _id: 14,
            name: "영상통화 안 될 때",
            replay: false,
            serachList: ["전화", "영상통화", "영상통화안됨", "영상통화안될때"],
            url: "/call/videocall_failed",
          },
          {
            _id: 15,
            name: "영상통화 카메라",
            replay: false,
            serachList: ["전화", "영상통화", "영상통화카메라", "통화카메라"],
            url: "/call/videocall_camera",
          },
          {
            _id: 16,
            name: "통화녹음",
            replay: false,
            serachList: ["전화", "녹음", "전화녹음", "통화녹음"],
            url: "/call/recording",
          },
          {
            _id: 17,
            name: "녹음 자동 설정",
            replay: false,
            serachList: [
              "전화",
              "녹음",
              "전화녹음",
              "통화녹음",
              "자동녹음",
              "녹음자동설정",
            ],
            url: "/call/autorecording_settings",
          },
          {
            _id: 18,
            name: "통화녹음 목록",
            replay: false,
            serachList: [
              "전화",
              "녹음",
              "전화녹음",
              "통화녹음",
              "통화녹음확인",
              "통화녹음목록",
            ],
            url: "/call/recording_list",
          },
          {
            _id: 19,
            name: "내 소리 차단 해제",
            replay: false,
            serachList: [
              "전화",
              "전화소리안들림",
              "전화소리",
              "내소리차단해제",
              "차단해제",
              "차단",
              "해제",
            ],
            url: "/call/unblock_mysound",
          },
        ],
      },
      {
        name: "연락처",
        guideList: [
          {
            _id: 20,
            name: "연락처 추가",
            replay: false,
            serachList: ["연락처", "연락처추가"],
            url: "/contact/add",
          },
          {
            _id: 21,
            name: "연락처 삭제",
            replay: false,
            serachList: ["연락처", "연락처삭제"],
            url: "/contact/remove",
          },
          {
            _id: 22,
            name: "연락처 전송",
            replay: false,
            serachList: ["연락처", "연락처전송", "연락처보내는방법"],
            url: "/contact/send",
          },
          {
            _id: 23,
            name: "수신차단",
            replay: false,
            serachList: ["연락처", "차단", "연락처차단", "수신차단"],
            url: "/contact/block_reception",
          },
          {
            _id: 24,
            name: "단축번호 설정",
            replay: false,
            serachList: [
              "연락처",
              "단축번호",
              "단축키",
              "단축키설정",
              "단축번호설정",
            ],
            url: "/contact/setting_shortcut_numbers",
          },
          {
            _id: 25,
            name: "연락처 검색(찾기)",
            replay: false,
            serachList: [
              "연락처",
              "연락처찾는방법",
              "연락처찾기",
              "연락처검색",
              "연락처검색(찾기)",
              "친구찾기",
            ],
            url: "/contact/serach",
          },
        ],
      },
      {
        name: "카메라",
        guideList: [
          {
            _id: 26,
            name: "타이머 설정",
            replay: false,
            serachList: ["카메라", "카메라시간", "타이머", "타이머설정"],
            url: "/camera/timer_settings",
          },
          {
            _id: 27,
            name: "초점",
            replay: false,
            serachList: ["카메라", "카메라초점", "초점"],
            url: "/camera/focus",
          },
          {
            _id: 28,
            name: "확대 촬영",
            replay: false,
            serachList: [
              "카메라",
              "카메라크게촬영",
              "크게촬영",
              "확대",
              "확대촬영",
            ],
            url: "/camera/enlarged_photo",
          },
          {
            _id: 29,
            name: "QR 사용법",
            replay: false,
            serachList: ["카메라", "QR사용법", "QR코드", "QR"],
            url: "/camera/qr_instructions",
          },
          {
            _id: 30,
            name: "화면 비율 조절",
            replay: false,
            serachList: [
              "카메라",
              "화면비율조절",
              "카메라화면크기",
              "화면크기",
              "화면비율",
            ],
            url: "/camera/screen_ratio",
          },
        ],
      },
      {
        name: "구글",
        guideList: [
          {
            _id: 31,
            name: "구글이란?",
            replay: false,
            serachList: ["구글", "구글사용법"],
          },
          {
            _id: 32,
            name: "인터넷 사용법",
            replay: false,
            serachList: ["구글", "인터넷", "사용법", "인터넷사용법"],
          },
          {
            _id: 33,
            name: "구글 회원가입",
            replay: false,
            serachList: ["구글", "구글로그인", "회원가입", "구글회원가입"],
          },
        ],
      },
      {
        name: "Play 스토어",
        guideList: [
          {
            _id: 34,
            name: "어플 다운로드",
            replay: false,
            serachList: [
              "Play스토어",
              "플레이스토어",
              "어플다운로드",
              "앱다운로드",
              "다운로드",
            ],
          },
          {
            _id: 35,
            name: "어플 실행",
            replay: false,
            serachList: [
              "Play스토어",
              "플레이스토어",
              "다운된어플위치",
              "어플위치",
              "어플실행",
              "앱위치",
            ],
          },
          {
            _id: 36,
            name: "어플 삭제",
            replay: false,
            serachList: ["Play스토어", "플레이스토어", "삭제", "어플삭제"],
          },
        ],
      },
      {
        name: "카카오톡",
        guideList: [
          {
            _id: 37,
            name: "카카오톡 회원가입",
            replay: false,
            serachList: [
              "카카오톡",
              "카카오톡로그인",
              "카카오톡회원가입",
              "회원가입",
            ],
          },
          {
            _id: 38,
            name: "친구 찾기",
            replay: false,
            serachList: ["카카오톡", "친구찾기", "카카오톡친구"],
          },
          {
            _id: 39,
            name: "친구 찾기 안 될 때",
            replay: false,
            serachList: [
              "카카오톡",
              "친구찾기",
              "카카오톡친구",
              "친구찾기안될때",
            ],
          },
          {
            _id: 40,
            name: "페이스톡",
            replay: false,
            serachList: [
              "카카오톡",
              "영상통화",
              "카카오톡영상통화",
              "페이스톡",
            ],
          },
          {
            _id: 41,
            name: "사진/동영상 전송",
            replay: false,
            serachList: [
              "카카오톡",
              "카카오톡사진",
              "사진보내기",
              "카톡사진전송",
              "사진/동영상전송",
              "사진전송",
              "동영상전송",
            ],
          },
          {
            _id: 42,
            name: "받은 사진 확인",
            replay: false,
            serachList: [
              "카카오톡",
              "이전에받은사진확인",
              "카카오톡사진",
              "사진다시보기",
              "받은사진확인",
            ],
          },
          {
            _id: 43,
            name: "대화 내용 찾기",
            replay: false,
            serachList: ["카카오톡", "대화내용찾기", "대화내용"],
          },
          {
            _id: 44,
            name: "돈 송금하기",
            replay: false,
            serachList: [
              "카카오톡",
              "돈보내기",
              "송금하기",
              "송금",
              "카카오페이",
              "돈송금하기",
            ],
          },
          {
            _id: 45,
            name: "선물하기",
            replay: false,
            serachList: ["카카오톡", "카카오톡선물", "선물"],
          },
        ],
      },
      {
        name: "네이버 지도",
        guideList: [
          {
            _id: 46,
            name: "어플 다운로드",
            replay: false,
            serachList: ["네이버지도", "어플다운로드"],
          },
          {
            _id: 47,
            name: "네비게이션(차량)",
            replay: false,
            serachList: [
              "네이버지도",
              "위치찾기",
              "길찾기",
              "네비게이션",
              "네비게이션(차량)",
              "자동차",
              "차량",
            ],
          },
          {
            _id: 48,
            name: "목적지 찾기(도보)",
            replay: false,
            serachList: [
              "네이버지도",
              "위치찾기",
              "길찾기",
              "목적지",
              "목적지찾기",
            ],
          },
          {
            _id: 49,
            name: "교통수단 찾기",
            replay: false,
            serachList: [
              "네이버지도",
              "위치찾기",
              "낄찾기",
              "버스",
              "지하철",
              "교통수단",
              "교통수단찾기",
            ],
          },
        ],
      },
      {
        name: "유튜브",
        guideList: [
          {
            _id: 50,
            name: "영상 검색",
            replay: false,
            serachList: ["유튜브", "영상검색"],
          },
          {
            _id: 51,
            name: "전체 화면",
            replay: false,
            serachList: ["유튜브", "영상큰화면", "전체화면"],
          },
          {
            _id: 52,
            name: "작은 화면",
            replay: false,
            serachList: ["유튜브", "영상작은화면", "작은화면"],
          },
          {
            _id: 53,
            name: "고화질 설정",
            replay: false,
            serachList: [
              "유튜브",
              "영상잘보이게",
              "영상고화질",
              "영상화질",
              "고화질설정",
            ],
          },
          {
            _id: 54,
            name: "자막",
            replay: false,
            serachList: ["유튜브", "자막"],
          },
          {
            _id: 55,
            name: "광고 넘기는 방법",
            replay: false,
            serachList: [
              "유튜브",
              "광고스킵",
              "스킵",
              "넘김",
              "광고넘김",
              "광고넘기는방법",
            ],
          },
        ],
      },
    ],
  },
  getters: {
    getApplicationList(state) {
      return state.list;
    },
    getTheseDaysGuideList(state) {
      const data = state.list.filter((v: ListType) => {
        return v.name == "요즘세상";
      });
      return data[0].guideList;
    },
    getMessageGuideList(state) {
      const data = state.list.filter((v: ListType) => {
        return v.name == "문자";
      });
      return data[0].guideList;
    },
    getCallGuideList(state) {
      const data = state.list.filter((v: ListType) => {
        return v.name == "전화";
      });
      return data[0].guideList;
    },
    getContactGuideList(state) {
      const data = state.list.filter((v: ListType) => {
        return v.name == "연락처";
      });
      return data[0].guideList;
    },
    getCameraGuideList(state) {
      const data = state.list.filter((v: ListType) => {
        return v.name == "카메라";
      });
      return data[0].guideList;
    },
    getGoogleGuideList(state) {
      const data = state.list.filter((v: ListType) => {
        return v.name == "구글";
      });
      return data[0].guideList;
    },
    getPlayStoreGuideList(state) {
      const data = state.list.filter((v: ListType) => {
        return v.name == "Play 스토어";
      });
      return data[0].guideList;
    },
    getKakaotalkGuideList(state) {
      const data = state.list.filter((v: ListType) => {
        return v.name == "카카오톡";
      });
      return data[0].guideList;
    },
    getNaverMapGuideList(state) {
      const data = state.list.filter((v: ListType) => {
        return v.name == "네이버 지도";
      });
      return data[0].guideList;
    },
    getYoutubeGuideList(state) {
      const data = state.list.filter((v: ListType) => {
        return v.name == "유튜브";
      });
      return data[0].guideList;
    },
    getReplayList(state) {
      let count = 0;
      const data = state.list.map((v) => {
        let countApp = 0;
        const guideData = v.guideList.filter((v2) => {
          if (v2.replay == true) {
            countApp += 1;
          }
          return v2.replay == true;
        });
        count += countApp;

        return { name: v.name, guideList: guideData, count: countApp };
      });
      return { data, count: count };
    },
    getSearch: (state) => (input: string) => {
      const inputText = input.split(" ").join("");
      const data = state.list.reduce((acc: any, v) => {
        const guideData = v.guideList.filter((v2) => {
          const filterData = v2.serachList.filter((i) => {
            return inputText.includes(i);
          });
          return filterData.length > 0;
        });
        if (guideData.length > 0) {
          return [...acc, { name: v.name, guideList: guideData }];
        }
        return acc;
      }, []);
      return data;
    },
    getGuideName: (state) => (id: number) => {
      for (let v of state.list) {
        for (let y of v.guideList) {
          console.log(y._id, id);
          if (y._id == id) {
            console.log("test", y.name);
            return y.name;
          }
        }
      }
    },
    getGuideList: (state) => (name: string, guideList: string[]) => {
      console.log(guideList);
      const data = state.list.filter((v: ListType) => {
        return v.name == name;
      });
      console.log(name, guideList, data[0]);
      const data2 = data[0].guideList.filter((v) => {
        return guideList.indexOf(v.name) != -1;
      });
      return data2;
    },
    getGuide: (state) => (name: string, guide: string) => {
      const data = state.list.filter((v: ListType) => {
        return v.name == name;
      });
      console.log(name, guide, data);
      const data2 = data[0].guideList.filter((v) => {
        return guide == v.name;
      });
      console.log(data2);
      return data2[0];
    },
    getRouter: (state) => (name: string, guideName: string) => {
      for (let v of state.list) {
        console.log(v.name == name, name, v.name);
        if (v.name == name) {
          for (let y of v.guideList) {
            if (y.name == guideName) {
              return y.url;
            }
          }
        }
      }
    },
  },
  actions: {},
  mutations: {
    changeReplay(
      state,
      data: { name: string; guideName: string; status: boolean }
    ) {
      let filterData = state.list.filter((v: ListType) => {
        return v.name == data.name;
      });
      const filterData2 = filterData[0].guideList.map((v: GuideListType) => {
        if (v.name == data.guideName) {
          v.replay = data.status;
        }
        return v;
      });

      const list = state.list.map((v) => {
        if (v.name == data.name) {
          v.guideList = filterData2;
        }
        return v;
      });
    },
  },
};
