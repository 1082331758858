<template>
  <div id="root">
    <router-view />
  </div>
</template>

<style lang="scss">
@import url(http://fonts.googleapis.com/earlyaccess/nanumgothic.css);

* {
  box-sizing: border-box;
  font-family: "Nanum Gothic";
}

html,
body {
  height: 100%;

  margin: 0px;
  padding: 0px;

  background-color: #f5f5f5;

  overflow: hidden;
}
p,
ul,
li,
ol,
a,
header,
img,
main,
footer,
body,
span,
div {
  padding: 0;
  margin: 0;
}

#app {
  height: 100%;
}

#root {
  max-width: 440px;
  height: 100%;
  margin: 0 auto;
  background-color: white;

  box-sizing: content-box;

  position: relative;
}
#root {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
#root::-webkit-scrollbar {
  display: none;
}
p {
  margin: 0px;
}
@media screen and (max-width: 440px) {
  #root {
    max-width: 100%;
    border: 0px;
  }
}
</style>
