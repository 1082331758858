import { Module } from "vuex";
import { RootState } from "../index";

export interface SettingState {
  sound: boolean;
  caption: boolean;
  time: "30초" | "50초" | "70초";
  voice: "남성" | "여성" | null;
}

export const setting: Module<SettingState, RootState> = {
  state: {
    sound: true,
    caption: true,
    time: "30초",
    voice: null,
  },
  getters: {
    getSound(state) {
      return state.sound;
    },
    getCaption(state) {
      return state.caption;
    },
    getTime(state) {
      return state.time;
    },
    getVoice(state) {
      return state.voice;
    },
  },
  actions: {},
  mutations: {
    changeSound(state, status: boolean) {
      state.sound = status;
    },
    changeCaption(state, status: boolean) {
      state.caption = status;
    },
    changeVoice(state, status: "남성" | "여성") {
      state.voice = status;
    },
    changeTime(state, status: "30초" | "50초" | "70초") {
      state.time = status;
    },
  },
};
