import { createStore } from "vuex";

import createPersistedState from "vuex-persistedstate";

import { setting, SettingState } from "@/store/modules/setting";
import { user, UserState } from "@/store/modules/user";
import { application, ApplicationState } from "@/store/modules/application";

export interface RootState {
  Setting: SettingState;
  User: UserState;
  Application: ApplicationState;
}

const plugins = [createPersistedState()];

export default createStore({
  modules: { setting, user, application },
  plugins,
});
